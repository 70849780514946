<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <b-link to="/" class="brand-logo">
                <img
                    src="@/assets/duplioAsset/duplioAssets/DUPLIO3.png"
                    width="100px"
                    alt=""
                />
            </b-link>

            <!-- Left Text-->
            <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
                <div
                    class="w-100 d-lg-flex align-items-center justify-content-center px-5"
                >
                    <b-img fluid :src="imgUrl" alt="Forgot password V2" />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Forgot password-->
            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <b-card-title class="mb-1">
                        {{ $t(locale.title) }} 🔒
                    </b-card-title>
                    <b-card-text class="mb-2">
                        {{ $t(locale.subTitle) }}
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="form">
                        <b-form
                            class="auth-forgot-password-form mt-2"
                            @submit.prevent="validationForm"
                        >
                            <b-form-group
                                :label="$t(locale.emailLabel)"
                                label-for="forgot-password-email"
                            >
                                <validation-provider
                                    #default="{errors}"
                                    :name="$t(locale.emailLabel)"
                                    vid="email"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        id="forgot-password-email"
                                        v-model="userEmail"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        name="forgot-password-email"
                                        placeholder="john@example.com"
                                    />
                                    <small
                                        class="text-danger"
                                        v-text="errors[0]"
                                    />
                                </validation-provider>
                            </b-form-group>

                            <b-button
                                type="submit"
                                variant="primary"
                                block
                                v-text="$t('ForgotPasswordPage.Button')"
                            />
                        </b-form>
                    </validation-observer>

                    <p class="text-center mt-2">
                        <b-link :to="{name: 'login'}">
                            <feather-icon icon="ChevronLeftIcon" />
                            {{ $t(locale.backToLogin) }}
                        </b-link>
                    </p>
                </b-col>
            </b-col>
            <!-- /Forgot password-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {required, email} from '@validations';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            userEmail: '',
            sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
            // validation
            required,
            email,
            locale: {
                title: 'ForgotPasswordPage.CardTitle',
                subTitle: 'ForgotPasswordPage.CardSubtitle',
                emailLabel: 'ForgotPasswordPage.EmailLabel',
                btn: 'ForgotPasswordPage.Button',
                backToLogin: 'ForgotPasswordPage.BackToLogin',
            },
        };
    },
    computed: {
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg');
                return this.sideImg;
            }
            return this.sideImg;
        },
    },
    methods: {
        validationForm() {
            this.$refs.form.validate().then(success => {
                if (success) {
                    this.$useJwt
                        .requestResetPassword({
                            email: this.userEmail,
                        })
                        .then(res => {
                            const {data} = res;
                            let msg =
                                localStorage.getItem('mainLang') == 'sv'
                                    ? data.message
                                    : data.eng_message;
                            this.popup(
                                msg,
                                'success',
                                this.$t('Message.Success')
                            );
                        })
                        .catch(err => {
                            if (err.response) {
                                let msg =
                                    localStorage.getItem('mainLang') == 'sv'
                                        ? err.response.data.message
                                        : err.response.data.eng_message;
                                this.popup(
                                    msg,
                                    'danger',
                                    this.$t('Message.Failed')
                                );

                                this.$refs.form.setErrors({
                                    email: [msg],
                                });
                            }
                        });
                }
            });
        },

        popup(message, variant, title) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    text: message,
                    title: title,
                    icon:
                        variant == 'success'
                            ? 'CheckIcon'
                            : 'AlertTriangleIcon',
                    variant: variant,
                },
            });
        },
    },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
